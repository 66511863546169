import "./Footer.css";
import "bootstrap/dist/css/bootstrap.css";
import logoEurope from "../Image/EN_Co-fundedbytheEU_RGB_POS.png";
//import logoImage from "../Image/logo.jpg";
//import iconfb from "../Image/icon_fb.png";
// import iconinstagram from "../Image/icon_instagram.png";
// import iconlinkedin from "../Image/icon_linkedin.png";
// import icontweeter from "../Image/icon_tweeter.png";
// import iconyoutube from "../Image/icon_youtube.png";

// import { Button, Container, Row, Col } from "reactstrap";

function Footer() {
  return (
    <>
      <section className="footerpage">
        <div className="containerpage">
          2023 ©
          <a href="https://univ-unita.eu/Sites/unita/en/" target="_blank" rel="noreferrer">
            unita - universitas montium
          </a>
          <img src={logoEurope} alt="Logo" className="logo" />
        </div>
      </section>
    </>
  );
}

export default Footer;
