//import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
//import logoImage from "./Image/logo.jpg";
//import PrimaryImage from "./Image/primaryLogo.png";
// import CardSection from "./Views/CardSection";
// import CardSection2 from "./Views/CardSection2";
import CardSectionFusion from "./Views/CardSectionFusion";
import Navbar from "./Views/Navbar";
import Footer from "./Views/Footer";
//import HeroSection from "./Views/HeroSection";
import ReactGA from "react-ga4";
//import React, { useState, useRef, useEffect } from "react";
import React, { useEffect } from "react";
//import { Button, Container, Row, Col } from "reactstrap";
//import TwoLigne from "./Views/TwoLigne";

function App() {
  useEffect(() => {
    try {
      // ReactGA.initialize("G-6VZH1HCE59");
      ReactGA.initialize("G-MCQ01RLT3P");
      // console.log("Google Analytics is initialized!");
      ReactGA.send("pageview");
      ReactGA.event({
        category: "visit",
        action: "New Visiter",
      });
    } catch (error) {
      console.log("Google Analytics initialization failed!");
    }
  }, []);
  return (
    <>
      <Navbar />

      {/* <HeroSection /> */}

      {/* <div className="bleubg">
        <CardSection />
      </div> */}
      {/*
      <Row>
        <Col md="12" xs="12">
          <TwoLigne />
        </Col>
      </Row> */}
      {/* <div className="bleubg">
        <CardSection2 />
      </div> */}
      <div >
        <CardSectionFusion />
      </div>
      <Footer></Footer>
    </>
  );
}

export default App;
