import "./Navbar.css";
import "bootstrap/dist/css/bootstrap.css";
import logoImage from "../Image/logo-unita-blanc.png";
import iconfb from "../Image/SocialMedia/icon_fb.png";
import iconinstagram from "../Image/SocialMedia/icon_instagram.png";
import iconlinkedin from "../Image/SocialMedia/icon_linkedin.png";
import icontweeter from "../Image/SocialMedia/icon_tweeter.png";
import iconyoutube from "../Image/SocialMedia/icon_youtube.png";

//import { Button, Container, Row, Col } from "reactstrap";
import { Row, Col } from "reactstrap";
//import TwoLigne from "./TwoLigne";
function Navbar() {
  return (
    <>
      <Row>
        <Col md="12" xs="12" className="nopadding justify-content-md-center">
          <div className="background-image pt-5 pb-5">
            <div className="logo-container">
              <img src={logoImage} alt="Logo" className="logo" />
              <h1 className="slogan">Unita Virtual Mobilities</h1>
              <h2 className="slogan">Course Catalog 2024-2025</h2>
              <div className="col col-xs-3" style={{ textAlign: "right" }}>
                {/* <div>
          <strong style={{ color: "white" }}>Social Media</strong>
        </div> */}
                <div className="row d-flex justify-content-center">
                  <div
                    className="col col-sm-2"
                    style={{ paddingBottom: "10px" }}
                  >
                    <a
                      href="https://www.facebook.com/UniversitasMontium"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={iconfb} alt="" width="32" height="32" />
                    </a>
                  </div>
                  <div
                    className="col col-sm-2"
                    style={{ paddingBottom: "10px" }}
                  >
                    <a
                      href="https://twitter.com/UMontium"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={icontweeter} alt="" width="32" height="32" />
                    </a>
                  </div>
                  <div
                    className="col col-sm-2"
                    style={{ paddingBottom: "10px" }}
                  >
                    <a
                      href="https://www.instagram.com/universitasmontium/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={iconinstagram} alt="" width="32" height="32" />
                    </a>
                  </div>
                  <div
                    className="col col-sm-2"
                    style={{ paddingBottom: "10px" }}
                  >
                    <a
                      href="https://www.linkedin.com/company/universitasmontium"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={iconlinkedin} alt="" width="32" height="32" />
                    </a>
                  </div>
                  <div
                    className="col col-sm-2"
                    style={{ paddingBottom: "10px" }}
                  >
                    <a
                      href="https://www.youtube.com/channel/UC8yRk03Hp931dIb7Dn5N1zw"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={iconyoutube} alt="" width="32" height="32" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Navbar;
